// Charge la base commune
require('./common.js')

// import bundle from './form.module.js' // lazy-loading code splitting (se charge uniquement en cas de call lazy-loading like)
// import _ from 'lodash' // lazy-loading dynamic import (se charge uniquement en cas de call lazy-loading like)

// Styles librairies
// import './js/libs/malihu-custom-scrollbar-plugin-master/jquery.mCustomScrollbar.css'

// Styles custom
// import './sass/pages/search.scss'

// Scripts librairies
// import exec from 'imports-loader?$=jquery!script-loader!./js/libs/malihu-custom-scrollbar-plugin-master/jquery.mCustomScrollbar.concat.min.js'

// Pages
// var realisationsPage = require('exports-loader?realisationsPage!./js/pages/realisations.js')

// actions principales communes à toutes les pages
$(document).ready(function () {
  /*
  $('#clickme').on('click', function(e) {
    bundle((form) => {
      const file = require('./form.bundle.js')
    })
  */
//    import(/* webpackChunkName: "print" */ './print').then(module => {
//      const print = module.default
//      print()
//     })
})
